import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import AsyncSelect from 'react-select/async';
import IntlTelInput from 'react-intl-tel-input';

const ContactForm = ({ data }) => {
  
  const {t} = useTranslation();
  const {language} = useI18next();

  // Country
  const [countrySelected, setCountrySelected] = useState(null);

  // Handle Change
  const handleChangeCountry = value => {
    setCountrySelected(value);
    setValue("country",value.code)
  }

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? "0 0 1rem 0 rgba(140, 152, 164, 0.25)" : "none",
      padding: 3,
    }),
  }

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#377dff",
      danger: '#ed4c78',
      dangerLight: '#f5ca99',
      neutral0: '#ffffff',
      neutral5: '#f7faff',
      neutral10: '#f8fafd',
      neutral20: "rgba(33, 50, 91, 0.1)",
      neutral30: '#bdc5d1',
      neutral40: '#97a4af',
      neutral50: '#8c98a4',
      neutral60: '#677788',
      neutral70: '#71869d',
      neutral80: '#1e2022',
      neutral90: '#21325b',
    },
    // Other options you can use
    borderRadius: 4,
    boxShadow: 'none',
  });

  // load options using API call
  const loadOptionsCountry = (keyword) => {
    return fetch(process.env.GATSBY_API_URL + `countries/search/results?keyword=${keyword}&sort_by=title_asc`, {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "language-code": language,
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(result => {
      if(result.error === false){
        const country =  result.data.results;
        return country;
      }else if(result.error === true){
        console.log(result);
      }
    })
    .catch(error => {
      console.log(error);      
    });
  };

  // Telephone
  const handleChangeTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  const handleBlurTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  // Submit
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    await fetch(process.env.GATSBY_API_URL + 'messages', {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "form-uuid": process.env.GATSBY_FORM_CONTACT_UUID,
        "language-code": language,
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(result => {
      if(result.error === false){
        setSubmitted(true);
        reset();
      }else if(result.error === true){
        setError(
          "submit",
          "submitError",
        );
      }
    })
    .catch(error => {
      console.log(error);      
      setError(
        "submit",
        "submitError",
        `${t('contactForm.error')} ${error.message}`
      );  
    });
  };

  const showSubmitError = msg => <p className="text-danger">{t('contactForm.error')}</p>

  const showSubmitted = (
    <div className="text-center">
      <h3>{t('contactForm.sent.title')}</h3>
      <p>{t('contactForm.sent.subtitle')}</p>
      <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/la-small-agency-message.svg" alt="Image Description" />
      <button type="button" className="btn btn-sm btn-primary text-white" onClick={() => setSubmitted(false)} aria-hidden="true">
        {t('contactForm.sendAnotherMessage')}
      </button>
    </div>
  );

  const showForm = (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="first_name">{t('contactForm.firstName.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                className="form-control"
                placeholder={t('contactForm.firstName.placeholder')}
                maxLength="100"
                {...register("first_name", { required: true })}
                disabled={isSubmitting}
              />
              {errors.first_name && <div className="text-danger">{t('contactForm.firstName.required')}</div>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="last_name">{t('contactForm.lastName.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                className="form-control"
                placeholder={t('contactForm.lastName.placeholder')}
                maxLength="100"
                {...register("last_name", { required: true })}
                disabled={isSubmitting}
              />
              {errors.last_name && <div className="text-danger">{t('contactForm.lastName.required')}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="enterprise">{t('contactForm.enterprise.label')}</label>
              <input
                type="text"
                name="enterprise"
                id="enterprise"
                className="form-control"
                placeholder={t('contactForm.enterprise.placeholder')}
                maxLength="100"
                {...register("enterprise", { required: false })}
                disabled={isSubmitting}
              />
              {errors.enterprise && <div className="text-danger">{t('contactForm.enterprise.required')}</div>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="country">{t('contactForm.country.label')}</label>
              <Controller
                name="country"
                id="country"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    isClearable
                    cacheOptions
                    defaultOptions
                    value={countrySelected}
                    getOptionLabel={e => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={e.flag.image} alt={e.title} width={16}/>
                        <span style={{ marginLeft: 5 }}>{e.title}</span>
                      </div>
                    )}
                    getOptionValue={e => e.code}
                    loadOptions={loadOptionsCountry}
                    onChange={handleChangeCountry}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    placeholder={t('contactForm.country.placeholder')}
                  />
                )}
              />
              {errors.country && <div className="text-danger">{t('contactForm.country.required')}</div>}
            </div>
          </div>
        </div>
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="email">{t('contactForm.email.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            placeholder={t('contactForm.email.placeholder')}
            maxLength="255"
            {...register("email", { required: true })}
            disabled={isSubmitting}
          />
          {errors.email && <div className="text-danger">{t('contactForm.email.required')}</div>}
        </div>
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="telephone">{t('contactForm.telephone.label')}</label>
          <Controller
            name="telephone"
            id="telephone"
            maxLength="20"
            rules={{ required: false }}
            disabled={isSubmitting}
            control={control}
            render={({ field }) => (
              <IntlTelInput
                inputClassName="form-control"
                nationalMode={false}
                autoPlaceholder={true}
                autoHideDialCode={false}
                format
                formatOnInit={true}
                preferredCountries={["fr", "gb", "ru"]}
                onPhoneNumberChange={handleChangeTelephone}
                onPhoneNumberBlur={handleBlurTelephone}
                placeholder={t('contactForm.telephone.placeholder')}
              />
            )}
          />
          {errors.telephone && <div className="text-danger">{t('contactForm.telephone.required')}</div>}
        </div>
        <div className="form-group mt-1">
          <label htmlFor="question">{t('contactForm.message.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
          <textarea
            name="message"
            id="message"
            rows="5"
            className="form-control"
            placeholder={t('contactForm.message.placeholder')}
            maxLength="1000"
            {...register("message", { required: true })}
            disabled={isSubmitting}
          ></textarea>
          {errors.message && (
            <div className="text-danger">{t('contactForm.message.required')}</div>
          )}
        </div>
        <div className="text-center mt-3">
          <button type="submit" className="btn btn-lg btn-primary text-white" disabled={isSubmitting}><i className="bi bi-send me-1"></i> {t('contactForm.send')}</button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <div>{submitted ? showSubmitted : showForm}</div>
      <div className="text-center my-3">
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
    </div>
  );
};

export default ContactForm